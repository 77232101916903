@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans'), local('Noto Sans JP');
}

@layer base {
  html {
    font-family: 'Noto Sans', 'Noto Sans JP', 'sans-serif';
    scroll-behavior: smooth;
  }
}

@layer components {
  .word-keep-all {
    word-break: keep-all;
  }
  .lp-container {
    @apply w-[320px] xs:w-[342px] md:w-[600px] lg:w-[900px] xl:w-[1064px];
  }
  .quarter-circle {
    @apply relative w-[100px] h-[100px] after:content-[''] after:absolute after:border-r-[100px] after:border-t-[100px] after:border-primary-200 after:rounded-tl-[100px];
  }
  .lg-quarter-circle {
    @apply relative w-[250px] h-[250px] after:content-[''] after:absolute after:border-r-[250px] after:border-t-[250px] after:border-primary-200 after:rounded-tl-[250px];
  }
  .card-container {
    @apply w-[320px] xs:w-[342px] md:w-[600px] lg:w-[1024px];
  }
  .card-list {
    @apply flex justify-center md:justify-start flex-wrap gap-3;
  }
  .whitepaper-card-list {
    @apply flex justify-center md:justify-start flex-wrap gap-8 md:gap-4;
  }
  .sidebar {
    @apply flex flex-col items-center w-[320px] xs:w-full md:w-[600px] lg:w-[900px] xl:w-[250px] lg:items-start;
  }
  .article-container {
    @apply w-full lg:w-[1024px];
  }

  .lpcv-container {
    @apply w-[320px] xs:w-full lg:w-[900px];
  }
  .lpcv-sidebar {
    @apply flex flex-col items-center xs:w-full xl:w-[250px] lg:items-start;
  }
  .m-article-details {
    h2 {
      @apply font-semibold text-2xl flex items-baseline gap-2 relative right-3 my-6 before:min-w-[1.5rem] before:min-h-[1.5rem] before:block before:bg-primary-700 before:rounded-full before:relative before:top-[3px];
    }

    h3 {
      @apply text-xl flex items-center gap-2 relative right-[2px] my-5 before:w-1 before:h-6 before:block before:bg-secondary-500;
    }

    p {
      @apply text-base leading-7 tracking-[0.01em];
    }

    img {
      @apply my-5;
    }

    a {
      @apply block break-all text-[#1558D6] lg:text-[#1A0DAB];
    }

    span {
      @apply block break-all;
    }

    iframe {
      @apply w-full h-full aspect-video;
    }

    .scroll-table {
      @apply w-full overflow-x-scroll
    }

    table {
      @apply table-auto border-collapse my-4 rounded-[4px] max-w-full w-full
    }

    th {
      @apply border-b border-divided min-w-fit bg-gray-200 p-2
    }

    td {
      @apply border-b border-divided p-2 bg-white min-w-[180px]
    }
  }
}
/* Overlay for dimmed background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 999; 
}

/* Banner styles */
.popup-banner {
  position: fixed;
  bottom: 28px;
  right: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 0.5s ease, filter 0.3s ease;
  z-index: 1000; /* Ensure it's above the overlay */
}

.popup-banner img {
  width: 336px;
  height: 280px;
  display: block;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: -5px;
  right: -4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.close-button svg {
  width: 20px;
  height: 20px;
  color: black;
}

.popup-banner.fade-out {
  opacity: 0.5;
}

/* Dimmed background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Keep the banner bright when dimmed */
.popup-banner.bright {
  filter: brightness(1); /* Increase brightness */
  opacity: 1 !important; /* Ensure full opacity */
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.popup-banner:hover {
  filter: brightness(1);
}
.dimmed-background {
  background: rgba(224, 13, 13, 0.5) !important;
  transition: background 0.3s ease-in-out;
}
